var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import gql from "graphql-tag";
import SavedPropertyCard from "./SavedPropertyCard.vue";
import PropertyCardLoading from "../public/accommodation/PropertyCardLoading.vue";
import { appendConnection } from "../../utils/apollo";
import removeSavedProperty from "../../api/mutations/removeSavedProperty";
import mixins from "vue-typed-mixins";
import NotificationsMixin from "../ui/NotificationsMixin";
export default mixins(NotificationsMixin).extend({
    components: {
        SavedPropertyCard: SavedPropertyCard,
        PropertyCardLoading: PropertyCardLoading,
    },
    apollo: {
        viewerStudent: {
            fetchPolicy: "cache-and-network",
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query SavedPropertiesQuery($after: String) {\n          viewerStudent {\n            id\n            savedProperties(first: 10, after: $after) {\n              edges {\n                node {\n                  id\n                  propertyListing {\n                    id\n                    ...SavedPropertyCard_property\n                  }\n                }\n              }\n              pageInfo {\n                hasNextPage\n                endCursor\n              }\n            }\n          }\n        }\n        ", "\n      "], ["\n        query SavedPropertiesQuery($after: String) {\n          viewerStudent {\n            id\n            savedProperties(first: 10, after: $after) {\n              edges {\n                node {\n                  id\n                  propertyListing {\n                    id\n                    ...SavedPropertyCard_property\n                  }\n                }\n              }\n              pageInfo {\n                hasNextPage\n                endCursor\n              }\n            }\n          }\n        }\n        ", "\n      "])), SavedPropertyCard.options.fragments.property),
        },
    },
    computed: {
        hasMoreProperties: function () {
            return (!!this.viewerStudent &&
                this.viewerStudent.savedProperties.pageInfo.hasNextPage);
        },
        savedProperties: function () {
            if (!this.viewerStudent) {
                return [];
            }
            return this.viewerStudent.savedProperties.edges.map(function (_a) {
                var node = _a.node;
                return node;
            });
        },
        leftColumn: function () {
            return this.savedProperties.filter(function (_, i) { return i % 2 === 0; });
        },
        rightColumn: function () {
            return this.savedProperties.filter(function (_, i) { return i % 2 === 1; });
        },
    },
    methods: {
        onRemove: function (propertyId) {
            var _this = this;
            this.$apollo
                .mutate(removeSavedProperty(propertyId, function (cache, mutationResult) {
                if (!mutationResult.data) {
                    return;
                }
                //const { removeSavedProperty: { propertyListing: { id } } } = mutationResult.data
                //const cacheQuery = cache.readQuery({query: gql`{viewerStudent { id }}`});
                /*cache.writeQuery({
                query: GET_TODOS,
                data: { todos: todos.concat([addTodo]) },
              });*/
            }))
                .then(function () {
                // TODO: Just remove single SavedProperty instead of total refresh
                // Not sure how to do it within the apollo mutation update
                _this.$apollo.queries.viewerStudent.refetch();
                _this.displaySuccessMessage(_this.$t("Saved Property has been removed"));
            });
        },
        onLoadMore: function () {
            if (this.viewerStudent) {
                this.$apollo.queries.viewerStudent.fetchMore({
                    variables: {
                        after: this.viewerStudent.savedProperties.pageInfo.endCursor,
                    },
                    updateQuery: function (previousQueryResult, _a) {
                        var fetchMoreResult = _a.fetchMoreResult;
                        return __assign(__assign({}, previousQueryResult), { viewerStudent: __assign(__assign({}, previousQueryResult.viewerStudent), { savedProperties: appendConnection(previousQueryResult.viewerStudent.savedProperties, fetchMoreResult.viewerStudent.savedProperties) }) });
                    },
                });
            }
        },
        metaInfo: function () {
            return {
                title: this.$t("Saved properties").toString(),
            };
        },
    },
});
var templateObject_1;
