var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import PropertyCard from "../public/accommodation/PropertyCard.vue";
import gql from "graphql-tag";
export default Vue.extend({
    components: {
        PropertyCard: PropertyCard,
    },
    fragments: {
        property: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SavedPropertyCard_property on PropertyListing {\n        id\n        isStudentSaved\n        ...PropertyCard_property\n      }\n      ", "\n    "], ["\n      fragment SavedPropertyCard_property on PropertyListing {\n        id\n        isStudentSaved\n        ...PropertyCard_property\n      }\n      ", "\n    "])), PropertyCard.options.fragments.property),
    },
    props: {
        property: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            showConfirmRemove: false,
        };
    },
    methods: {
        onRemoveSaved: function () {
            this.showConfirmRemove = true;
        },
        onConfirmRemove: function () {
            this.$emit("remove", this.property.id);
            this.showConfirmRemove = false;
        },
        onCancelRemove: function () {
            this.showConfirmRemove = false;
        },
    },
});
var templateObject_1;
